import create from 'zustand';

import { ReduxDevTools } from '../helpers/storeLogger';
import { segment } from '../segment';
import { KonakartService } from '../services/konakart';
import { getCustomerProductSet, updateProductsForStaffAccount } from '../swr/helper';
import { T_Product, T_OrderProduct, T_OrderRequest, ServiceStatus, T_Billing } from '../types';
import { getTreatment } from '../helpers/useSplitIO';
import { SPLITIO_KEY, productSkuIds } from '../config';

/**
 * Store for managing products that relate to konakart.
 * IMPORTANT: Does not do any update to konakart. That should be done via order store.
 */
interface ProductStore {
  /* isUpgrade is whether you're logged in or not. */
  isUpgrade: boolean;

  /* isCartTypeUpgrade is for analytics which depends on whether your're logged in or not, as well as if you select you're a Sky customer in BB SPA. */
  isCartTypeUpgrade: boolean;

  initializedProductStore: boolean;

  updatedProductStore: boolean;

  products: T_Product[];

  skyTvDiscountProduct?: T_OrderRequest[];

  setProductStoreUpgrade: (isUpgrade: boolean) => void;

  setProductStoreCartTypeUpgrade: (isCartTypeUpgrade: boolean) => void;

  getBasketItemsToAddToOrder: (isStaffAccount?: boolean) => T_OrderRequest[];

  getSkyTvDiscountProduct: (has150BBor151BB: any, hasDiscountPrice?:string) => void;

  loadProductStore: (
    kkService: KonakartService,
    customerId?: string,
    isStaffAccount?: boolean,
    billing?: T_Billing
  ) => Promise<void>;

  changeProductQuantity: (
    productId: number,
    quantity: number,
    trackEvent?: boolean,
    boxId?: string,
    overrides?: Pick<T_Product, 'inPromotion' | 'priceIncTax'>
  ) => number;

  updateFromOrderProducts: (orderProducts: T_OrderProduct[]) => void;

  updateBillingProductsFromOrderProducts: (billingProducts: T_Product[], orderProducts: T_OrderProduct[]) => void;

  clearProducts: (kkService: KonakartService) => void;

  updateProductDowngrade: (productId: number, value: boolean, occurrence?: string) => void;
}

const [useProductStore, productStoreApi] = create<ProductStore>(
  ReduxDevTools(
    (set, get) => ({
      isUpgrade: false,

      isCartTypeUpgrade: false,

      initializedProductStore: false,

      updatedProductStore: false,

      products: [],

      skyTvDiscountProduct: [],

      setProductStoreUpgrade: isUpgrade => {
        set({ isUpgrade });
      },

      setProductStoreCartTypeUpgrade: isCartTypeUpgrade => {
        set({ isCartTypeUpgrade });
      },

      loadProductStore: async (kkService, customerId, isStaffAccount = false, billing) => {
        let { enabled: overrideProductInfoFromKK } = await getTreatment(
          SPLITIO_KEY.SKYWEB_OVERRIDE_PRODUCT_INFO_FROM_KONAKART
        );
        let products = await kkService.getAllProduct(overrideProductInfoFromKK);
        // Update price to 0 for staff accounts
        if (isStaffAccount) {
          products = updateProductsForStaffAccount(products);
        }
        set({ initializedProductStore: true, products }, 'initialized');
      },

      getSkyTvDiscountProduct: (has150BBor151BB: any,hasDiscountPrice?:string) => {
        let products = get().products;
        //sky tv discount product will be enable when Dth and BB added in upgrade journey
        // BB coupons are 09061BB , 09060BB
        if(has150BBor151BB){
          const rateAfterTax = (parseFloat(has150BBor151BB?.rate?.afterTax)/100);
          let skyTvDiscountProduct =  products?.filter(p => p.sku === has150BBor151BB?.serviceCode)?.map(p => ({
            productId: p.id,
            quantity: 1,
            finalPriceIncTax: hasDiscountPrice ? (parseFloat(hasDiscountPrice)/100):rateAfterTax ?? 0,
            downgrade: p.downgrade ?? false,
            inPromotion: true,
            currentlySubscribed: true,
            occurrenceNumber: p.occurrenceNumber,
            broadbandPriceHold: p.broadbandPriceHold,
            isPhantom: p.isPhantom,
          }));
           set({ skyTvDiscountProduct })
        }

      },

      getBasketItemsToAddToOrder: (isStaffAccount?: boolean) => {
        let products = get().products;
        let toUpdateOrder = products
          .filter(p => p.quantityInCart > 0 && p.serviceStatus !== ServiceStatus.PendingInstall)
          .map(p => ({
            productId: p.id,
            quantity: p.quantityInCart,
            finalPriceIncTax: p.quantityBought > 0 ? p.priceBilled || 0 : p.priceIncTax,
            downgrade: p.downgrade ?? false,
            inPromotion: p.inPromotion ? p.inPromotion : isStaffAccount ? isStaffAccount : false,
            currentlySubscribed: p.quantityBought > 0,
            occurrenceNumber: p.occurrenceNumber,
            broadbandPriceHold: p.broadbandPriceHold,
            isPhantom: p.isPhantom,
          }));

        let skyTvDiscountProduct:T_OrderRequest[]|any= get().skyTvDiscountProduct;
        let isSkyTvDiscount = toUpdateOrder?.some( p => p.productId === skyTvDiscountProduct?.[0]?.productId);
        return skyTvDiscountProduct ?  !isSkyTvDiscount ? toUpdateOrder.concat(skyTvDiscountProduct):toUpdateOrder: toUpdateOrder;

      },

      /**
       * Handles adding/removing products in store.
       *
       * This is used only when a product is not currently subscribed.
       * `updateProductDowngrade` is responsible for updating a subscribed product.
       *
       * @param productId
       * @param quantity
       * @param trackEvent
       * @param boxId - Temporary box id assigned
       * @returns
       */
      changeProductQuantity: (
        productId,
        quantity,
        trackEvent = true,
        boxId?: string,
        overrides?: Pick<T_Product, 'inPromotion' | 'priceIncTax'>
      ) => {
        let { products, isUpgrade, isCartTypeUpgrade } = get();
        let existingProductQty = products.filter(p => p.id === productId && p.quantityInCart)?.length ?? 0;
        let diff = quantity - existingProductQty;
        let product = products.find(p => p.id === productId);

        if (diff === 0 || !product) return diff;
        if (diff < 0) {
          let index = products.findIndex(
            p => p.id === productId && !p.quantityBought && (boxId ? boxId === p.occurrenceNumber : true)
          );
          if (existingProductQty === 1) {
            products[index] = {
              ...product,
              priceIncTax: product.price0,
              downgrade: false,
              inPromotion: false,
              occurrenceNumber: undefined,
              quantityBought: 0,
              quantityInCart: 0,
            };
          } else {
            products.splice(index, 1);
          }
        } else {
          let newProduct: T_Product = {
            ...product,
            priceIncTax: overrides?.priceIncTax ?? product.price0,
            quantityBought: 0,
            quantityInCart: 1,
            downgrade: false,
            inPromotion: overrides?.inPromotion ?? false,
            occurrenceNumber: boxId,
          };

          if (existingProductQty === 0) {
            let index = products.findIndex(p => p.id === productId);
            products[index] = newProduct;
          } else {
            products = products.concat([newProduct]);
          }
        }

        if (trackEvent) {
          diff > 0
            ? segment.productAdded(product, isUpgrade || isCartTypeUpgrade, 1)
            : segment.productRemoved(product, isUpgrade || isCartTypeUpgrade);
        }

        set({ products }, 'changeProductQuantity');
        return diff;
      },

      /**
       * Handles updating the products for acquisition
       *
       * @param orderProducts products returned from konakart update order API
       */
      updateFromOrderProducts: orderProducts => {
        let products = get().products;
        let reduced = products.filter(p => !orderProducts.find(op => op.productId === p.id));

        products = reduced.concat(
          orderProducts.map(op => {
            const product = products.find(p => p.id === op.productId) ?? op.product;
            return {
              ...product,
              occurrenceNumber: op.occurrenceNumber,
              priceIncTax: op.price,
              quantityInCart: op.quantity,
              isPhantom: op.isPhantom,
            };
          })
        );

        set({ products }, 'update from order products');
        set({ updatedProductStore: true }, 'updated');
      },

      /**
       * Handles updating the products from billing
       *
       * @param billingProducts products from billing
       * @param orderProducts products from cart
       */
      updateBillingProductsFromOrderProducts: (billingProducts, orderProducts) => {
        let products = get().products;
        let reduced = billingProducts.filter(bp => !orderProducts.find(op => op.productId === bp.id));

        products = reduced.concat(
          orderProducts.map(op => {
            const product = products.find(p => p.id === op.productId) ?? op.product;
            return {
              ...product,
              priceBilled: op.finalPriceIncTax ?? op.price,
              priceIncTax: op.price ?? op.finalPriceIncTax,
              //priceIncTax: op.finalPriceIncTax ?? op.price,
              quantityBought: op.currentlySubscribed ? 1 : 0,
              quantityInCart: op.currentlySubscribed ? 1 : op.quantity,
              occurrenceNumber: op.occurrenceNumber,
              downgrade: op.downgrade,
              inPromotion: op.inPromotion,
              broadbandPriceHold: op.broadbandPriceHold,
              isPhantom: op.isPhantom,
            };
          })
        );

        set({ products }, 'update from order products');
        set({ updatedProductStore: true }, 'updated');
      },

      clearProducts: () => {
        let { products } = get();
        products = products.map(x => ({ ...x, quantityInCart: 0 }));
        set({ products }, 'reset products');
      },

      updateProductDowngrade: (productId, downgrade, occurrence) => {
        let { products } = get();
        let index = products.findIndex(p => p.id === productId && p.occurrenceNumber === occurrence);
        if (index > -1) {
          products = [
            ...products.slice(0, index),
            {
              ...products[index],
              downgrade,
            },
            ...products.slice(index + 1),
          ];
          set({ products }, 'updateProductDowngrade');
        }
      },
    }),
    'Product Store'
  )
);

export { useProductStore, productStoreApi };
