import { AxiosInstance } from 'axios';
import { PPVOccurrenceCableTypes } from '../config';

import {
  T_Billing,
  T_BillingDoc,
  T_BillingDetails,
  T_BillingOtherDoc,
  T_Customer,
  T_DesignatedAccount,
  T_Device,
  T_Entitlement,
  T_EPG_Remote_Record,
  T_MOP,
  T_MOP_Payload,
  T_Occurrence,
  T_PPV,
  T_PPV_HISTORY,
  T_PPV_Mobile,
  T_PPVPayload,
  T_Profile,
  T_ValidateAccount,
  T_VerifyAndLinkAccount,
  T_LinkAccount,
  T_Token,
  PaymentPayload,
  T_CustomerPrefs,
  T_OSDSendMsg,
  T_Eligibility_Offers,
} from '../types';

export class MyAccountService {
  constructor(private agent: AxiosInstance) {
    this.agent = agent;
  }
  public getBilling = async () => {
    const { data } = await this.agent.get<T_Billing>(`/my-account/billing`, {});
    return data;
  };
  public getBillingDetails = async () => {
    const { data } = await this.agent.get<T_BillingDetails>(`/my-account/customerBillingDetails`, {});
    return data;
  };
  public getBillingDocs = async () => {
    const { data } = await this.agent.get<T_BillingDoc[]>(`/my-account/billing/docs?isLetter=false`);
    return data;
  };
  public getBillingOtherDocs = async () => {
    const { data } = await this.agent.get<T_BillingOtherDoc[]>(`/my-account/billing/docs?isLetter=true`);
    return data;
  };

  public getMop = async () => {
    const { data } = await this.agent.get<T_MOP[]>(`/my-account/mop`, {});
    return data;
  };
  public updateMop = async (payload: Partial<T_MOP_Payload>) => {
    const { data } = await this.agent.post<T_MOP[]>(`/my-account/mop`, payload);
    return data;
  };

  public getBoxes = async () => {
    const { data } = await this.agent.get<T_Occurrence[]>(`/my-account/boxes`, {});
    /* Only return boxes. */
    return data
      .filter(o => PPVOccurrenceCableTypes.includes(o.occurrenceType))
      .sort(
        ({ occurrenceNumber: previousRating }, { occurrenceNumber: currentRating }) =>
          parseInt(previousRating) - parseInt(currentRating)
      );
  };

  public getOffers = async () => {
    const { data } = await this.agent.get<T_Eligibility_Offers>('/my-account/offers', {});
    return data;
  };

  public getPublicOffers = async (subscriberKey?: string) => {
    if (!subscriberKey) return null;

    const { data } = await this.agent.get<T_Eligibility_Offers | null>(
      `/my-account/public/offers?subscriberKey=${subscriberKey}`,
      {}
    );

    return data;
  };

  public getCustomer = async () => {
    const { data } = await this.agent.get<T_Customer>(`/my-account/customer`, {});
    return data;
  };
  public updateCustomer = async (payload: Partial<T_Customer>) => {
    const { data } = await this.agent.put<T_Customer>(`/my-account/customer`, payload);
    return data;
  };
  public updatePin = async (payload: { newPin: string; oldPin?: string; reset: boolean }) => {
    const { data } = await this.agent.put(`/my-account/customer/pin`, payload);
    return data;
  };

  public updatePassword = async (payload: { oldPassword: string; newPassword: string }) => {
    const { data } = await this.agent.put(`/my-account/user/password`, payload);
    return data;
  };

  public getDevices = async () => {
    const { data } = await this.agent.get<T_Device[]>(`/my-account/devices`, {});
    return data;
  };

  public updateDevices = async (payload: Partial<T_Device>) => {
    return await this.agent.put('/my-account/devices', payload);
  };
  public deleteDevices = async (deviceId: T_Device['deviceId']) => {
    return await this.agent.delete(`/my-account/devices/${deviceId}`);
  };

  public updateBox = async (payload: Partial<T_Occurrence>) => {
    return await this.agent.put(`/my-account/boxes/${payload.serialNumber}`, payload);
  };

  public getEntitlements = async () => {
    const { data } = await this.agent.get<T_Entitlement[]>(`/my-account/entitlements`, {});
    return data;
  };

  public getPPV = async (type = 'MOVIE') => {
    const { data } = await this.agent.get<T_PPV[]>(`/my-account/ppv`, {
      params: {
        type,
      },
    });
    return data;
  };

  public bookPPV = async (payload: T_PPVPayload) => {
    const { data } = await this.agent.post('/my-account/ppv', payload);
    return data;
  };

  public getPPVHistory = async () => {
    const { data } = await this.agent.get<T_PPV_HISTORY[]>('/my-account/ppv/history', {});
    return data;
  };

  public getPPVMobile = async () => {
    const { data } = await this.agent.get<T_PPV_Mobile>('/my-account/ppv/mobile');
    return data;
  };
  public updatePPVMobile = async (mobile: string) => {
    const { data } = await this.agent.put('/my-account/ppv/mobile', {
      mobile,
    });
    return data;
  };
  public deletePPVMobile = async () => {
    const { data } = await this.agent.delete('/my-account/ppv/mobile');
    return data;
  };
  public registerPPVMobile = async (mobile: string) => {
    const { data } = await this.agent.post('/my-account/ppv/mobile', {
      mobile,
    });
    return data;
  };

  public getProfile = async () => {
    const { data } = await this.agent.get<T_Profile>(`/my-account/profile`, {});
    return data;
  };

  public updateProfile = async (payload: Partial<T_Profile>) => {
    const { data } = await this.agent.post<T_Profile>(`/my-account/profile`, payload, {});
    return data;
  };

  public getDesignatedAccount = async () => {
    const { data } = await this.agent.get<T_DesignatedAccount>(`/my-account/account/designated`, {});
    return data;
  };

  public verifyAccount = async (payload: T_ValidateAccount) => {
    const { data } = await this.agent.put(`/my-account/account/verify`, payload, {});
    return data;
  };

  public verifyAndLinkAccount = async (payload: T_VerifyAndLinkAccount) => {
    try {
      const response = await this.agent.put(`/my-account/account/verifyAndLink`, payload, {});
      return response.data;
    } catch (err) {
      let errorObj = err?.response?.data;
      throw new Error(errorObj?.code);
    }
  };

  public linkAccount = async (payload: T_LinkAccount) => {
    try {
      const response = await this.agent.put(`/my-account/account/link`, payload, {});
      return response.data;
    } catch (err) {
      let errorObj = err?.response?.data;
      throw new Error(errorObj?.code);
    }
  };

  public getToken = async (token: string) => {
    try {
      const response = await this.agent.get<T_Token>(`/my-account/account/token`, {
        params: {
          token,
        },
      });
      return response.data;
    } catch (err) {
      let errorObj = err?.response?.data;
      throw new Error(errorObj?.code);
    }
  };

  public remoteRecord = async (payload: T_EPG_Remote_Record) => {
    const { data } = await this.agent.post('/my-account/recordings', payload);
    return data;
  };

  public getPaymentURL = async (payload: PaymentPayload) => {
    const { data } = await this.agent.post<{ url: string }>('/my-account/billing/pay', { ...payload, redirect: false });
    return data.url;
  };
  public OTPEmail = async (email: string) => {
    const { data } = await this.agent.post('/my-account/otp/email', { email });
    return data;
  };
  public OTPToken = async (email: string, code: string) => {
    const { data } = await this.agent.post('/my-account/otp/token', { email, code });
    return data;
  };

  public getTracking = async () => {
    const { data } = await this.agent.get('/my-account/tracking');
    return data;
  };

  public getCustomerPref = async () => {
    const { data } = await this.agent.get<T_CustomerPrefs>(`/my-account/customer-pref`);
    data.emails.frequency = data.emails.frequency ?? 'none';
    return data;
  };
  public updateCustomerPref = async (payload: Partial<T_CustomerPrefs>) => {
    const { data } = await this.agent.put<T_CustomerPrefs>(`/my-account/customer-pref`, payload);
    return data;
  };
  public OSDSendMsg = async (payload: T_OSDSendMsg) => {
    const { data } = await this.agent.post<T_OSDSendMsg>(`/my-account/osd/send`, payload);
    return data;
  };
}
